<template>
  <ol class="flex">
    <li
      v-for="(step, i) in steps"
      :key="i"
      class="flex-1 text-center"
      :class="{ 'is-previous': value > i + 1, 'is-active': value === i + 1 }"
    >
      <a
        href="javascript:void(0)"
        @click="$emit('move-step', i + 1)"
        class="flex flex-col items-center outline-none"
      >
        <component
          :is="`${step.icon}-icon`"
          class="icon w-10 h-10 fill-gray"
        ></component>
        <div class="step relative w-full">
          <div
            class="step-marker bg-gray-300 w-8 h-8 rounded-full my-2 mx-auto relative z-10"
          ></div>
        </div>
        {{ step.title }}
      </a>
    </li>
  </ol>
</template>

<script>
import MessageIcon from "@/assets/icons/message.svg";
import EmailIcon from "@/assets/icons/email.svg";
import SettingsIcon from "@/assets/icons/settings.svg";
import EuroIcon from "@/assets/icons/euro.svg";

export default {
  components: {
    MessageIcon,
    EmailIcon,
    SettingsIcon,
    EuroIcon,
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="postcss" scoped>
li:not(:first-child) .step::before {
  content: "";
  left: -50%;
  top: 50%;
  transform: translateY(-50%);
  @apply block absolute w-full h-2 bg-gray-300;
}

li.is-previous {
  @apply text-green-800;

  & .icon {
    @apply fill-green-800;
  }

  & .step-marker {
    @apply bg-green-800;
  }
}

li.is-active {
  @apply text-green-500 font-bold;

  & .icon {
    @apply fill-green;
  }

  & .step-marker {
    @apply bg-green-500;
  }
}

li.is-previous,
li.is-active {
  & .step::before {
    @apply bg-green-800;
  }
}
</style>
