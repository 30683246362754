<template>
  <div class="flex flex-wrap -mb-4">
    <button
      type="button"
      v-for="o in options"
      :key="o.value"
      @click.prevent="$emit('input', o.value)"
      class="btn-green-outline mr-4 mb-4"
      :class="{ 'is-active': value === o.value }"
    >
      {{ o.text }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: [Boolean, String, Number],
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
};
</script>

<style lang="postcss" scoped>
.btn-green-outline:last-child {
  @apply mr-0;
}
</style>
