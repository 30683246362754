import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
import * as rules from "vee-validate/dist/rules";
import startsWith from "lodash/startsWith";

// Install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("fr", fr);

extend("phone", {
  validate: (value) => {
    return startsWith(value, "0") && !startsWith(value, "00");
  },
  message:
    "Le numéro de téléphone doit commencer par 0X. Merci de vérifier votre saisie.",
});

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
