import Vue from "vue";
import trimEnd from "lodash/trimEnd";

/**
 * Set API call function
 */
let apiURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

Vue.prototype.$http = async (method, path, data) => {
  let response = await fetch(`${trimEnd(apiURL, "/")}/api/${path}`, {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

  if (response.status === 500) {
    throw Error("Erreur serveur.");
  }

  if (response.status >= 400 && response.status < 500) {
    let body = await response.json();
    throw Error(
      `Requête invalide. Veuillez revérifier votre saisie. Détail de l'erreur : ${body.detail}`
    );
  }

  return response.json();
};
