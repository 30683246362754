import CryptoJS from "crypto-js";

let key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_CRYPTO_KEY);

export function encrypt(value) {
  return CryptoJS.AES.encrypt(value, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}
