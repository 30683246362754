<template>
  <input
    v-model="numberValue"
    :id="id"
    class="form-control"
    inputmode="numeric"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number],
    id: String,
  },
  data: () => ({
    input: null,
  }),
  computed: {
    numberValue: {
      get() {
        return this.$formatNumber(this.value);
      },
      set(value) {
        this.$emit("input", parseInt(value.replace(/\s/g, ""), 10));
      },
    },
  },
};
</script>
