/**
 * Range formatter our tracking
 */
const getRange = (value, ranges, formatter = null) => {
  let number = parseInt(value, 10);

  if (isNaN(number)) {
    return null;
  }

  let stringify = (num) => {
    let s = num > 999 ? num / 1000 + "k" : num;
    return formatter ? formatter(s) : s;
  };

  let closest = ranges.reduce((a, b) => {
    return Math.abs(b - value) < Math.abs(a - value) ? b : a;
  });

  if (number < closest) {
    let prev = ranges[ranges.indexOf(closest) - 1];

    return prev
      ? `${stringify(prev)}-${stringify(closest)}`
      : `<${stringify(closest)}`;
  }

  if (number >= closest) {
    let next = ranges[ranges.indexOf(closest) + 1];

    return next
      ? `${stringify(closest)}-${stringify(next)}`
      : `>${stringify(closest)}`;
  }

  return null;
};

const formatNumber = (value) => {
  return value
    ? new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
      }).format(value)
    : null;
};

export { getRange, formatNumber };
