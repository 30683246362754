<template>
  <div class="text-green-900 text-bold relative">
    <span class="text-6xl lg:text-8xl">{{ formatNumber(price / 1200, 2) }}</span>
    <span
      class="text-xs lg:text-lg text-left absolute right-auto ml-2 mt-5 lg:mt-8"
    >
      €/mois<sup>(1)</sup><br />
      soit {{ formatNumber(price / 100, 2) }} €/an
    </span>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatNumber(value, digits = 0) {
      return new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(Math.round(value * 100) / 100);
    },
  },
};
</script>
