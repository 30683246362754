export default {
  data: () => ({
    steps: [],
    currentStep: 1,
    lastStep: 1,
    pending: false,
    errorMessage: null,
    form: [],
    result: {},
  }),
  computed: {
    isStart() {
      return this.currentStep === 1;
    },
    isEnd() {
      return this.currentStep === this.steps.length;
    },
    canPrev() {
      return !this.isStart && !this.End;
    },
    canNext() {
      return !this.End;
    },
    currentForm: {
      get() {
        return this.form[this.currentStep - 1];
      },
      set(value) {
        this.form[this.currentStep - 1] = value;
      },
    },
  },
  methods: {
    goPrev() {
      this.errorMessage = null;

      if (this.currentStep === 1) {
        return;
      }

      this.currentStep--;
    },
    goNext() {
      this.errorMessage = null;

      if (this.currentStep === this.steps) {
        return;
      }

      this.currentStep++;
      this.lastStep = this.currentStep;

      /**
       * Prevent double clic
       */
      setTimeout(() => {
        this.pending = false;
      }, 200);
    },
    moveToStep(i) {
      if (i <= this.lastStep) {
        this.currentStep = i;
      }
    },
  },
};
