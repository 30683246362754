<template>
  <div class="relative" v-click-outside="() => (showResult = false)">
    <input
      :id="id"
      class="form-control"
      :class="{ 'rounded-b-none': showResult }"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      autocomplete="off"
    />
    <ul
      class="absolute bg-white z-10 w-full p-4 border-gray-300 border border-t-0 rounded-b h-64 overflow-auto"
      v-if="showResult"
    >
      <li v-for="(c, i) in data" :key="i" class="font-bold mb-2">
        <a href="javascript:void(0)" @click="onSelect(c)">
          {{ c }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import qs from "qs";

let fetchApi = (query) => {
  return fetch(`https://geo.api.gouv.fr/communes?${qs.stringify(query)}`, {
    method: "GET",
    redirect: "follow",
  }).then((response) => response.json());
};

export default {
  props: {
    id: String,
    value: String,
    placeholder: String,
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      data: [],
      showResult: false,
      isFetching: false,
    };
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
      this.getAsyncData(e.target.value);
    },
    onSelect(city) {
      this.showResult = false;
      this.$emit("input", city);
    },
    getAsyncData: debounce(async function (q) {
      if (!q.length) {
        this.data = [];
        return;
      }

      let cities = [];

      this.isFetching = true;

      /**
       * On test sur nom puis code postal si aucun résultat
       */
      let data = await fetchApi({
        nom: q,
        boost: "population",
        limit: this.perPage,
      });

      if (data.length) {
        /**
         * Décomposition par code postal
         */
        data.forEach((c) => {
          cities.push(
            ...(c.codesPostaux || []).map((cp) => {
              return `${cp} - ${c.nom}`;
            })
          );
        });
      } else {
        /**
         * Test via code postal
         */
        data = await fetchApi({
          codePostal: q,
        });

        cities = data.map((c) => {
          return `${q} - ${c.nom}`;
        });
      }

      this.data = cities;
      this.showResult = true;

      this.isFetching = false;
    }, 500),
  },
};
</script>
