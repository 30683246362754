<template>
  <ValidationProvider
    :name="label.toLowerCase()"
    :rules="rules"
    v-slot="{ failedRules, failed }"
    tag="div"
  >
    <div class="form-group" :class="{ invalid: failed }">
      <label :for="labelFor">
        <span>{{ label }}<span v-if="required"> *</span></span>
      </label>

      <div class="form-input relative">
        <slot></slot>

        <ul class="errors">
          <li v-for="(message, type) in failedRules" :key="type">
            {{ message }}
          </li>
        </ul>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    labelFor: String,
    label: String,
    required: Boolean,
    rules: String,
  },
};
</script>

<style lang="postcss" scoped>
.form-group {
  @apply flex flex-col mb-10;

  & label {
    @apply mb-2;
  }

  > div {
    @apply flex-1 relative;
  }

  & /deep/ .form-control {
    @apply w-full;
  }

  @screen lg {
    @apply flex-row items-center;

    & label {
      @apply mr-8 mb-0;
    }
  }

  .errors {
    @apply hidden text-red-500 text-xs mt-1 absolute whitespace-nowrap;
  }

  &.invalid {
    & /deep/ .form-control {
      @apply border-red-500;
    }

    .errors {
      @apply block;
    }
  }
}

.input-currency .form-input::after {
  content: "€";
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  @apply absolute;
}
</style>
