<template>
  <div id="app">
    <div class="container py-8">
      <MainForm />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="postcss">
.grecaptcha-badge {
  @apply hidden;
}

.container {
  @apply px-4;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.btn-orange {
  @apply bg-orange-500 text-white font-bold w-40 h-16 rounded text-xl;

  svg {
    @apply mx-auto;
  }

  &:not(:disabled):hover {
    @apply bg-orange-700;
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
}

.btn-green-outline {
  transition: all 0.2s ease-out;
  @apply px-6 py-3 border border-gray-300 rounded;

  &:hover,
  &.is-active {
    @apply bg-green-800 text-white border-green-800;
  }
}

.form-control {
  @apply border-gray-300 border rounded py-3 px-4 font-bold;

  &:focus {
    @apply outline-none;
  }
}

.columns {
  @apply flex flex-col -m-2;

  @screen md {
    @apply flex-row;
  }
}

.column {
  @apply flex-1 p-2;
}

.content {
  a {
    @apply text-orange-500 font-bold underline;
  }
}
</style>
