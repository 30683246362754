export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    form: {},
  }),
  watch: {
    value: {
      handler(newVal) {
        this.form = newVal;
      },
      immediate: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
